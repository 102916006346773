<template>
  <div>
    <loading v-if="loading" />
    <div class="row" v-if="! loading">
      <div class="col-md-12">
        <a href="#" class="btn btn-danger btn-sm float-end" @click.prevent="destroy"><i class="lni lni-trash-can me-2"></i> Delete</a>
        <h2>{{ announcement.method }} #{{ announcement.id }}</h2>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body" v-if="announcement.method == 'email'">
            <div class="form-group">
              <label>Recipients</label>
              <input type="text" disabled v-model="announcement.type" class="form-control">
            </div>
            <div class="form-group">
              <label>Subject</label>
              <input type="text" disabled v-model="announcement.subject" class="form-control">
            </div>
            <div class="form-group">
              <label>Content</label>
              <div class="border p-3" v-html="announcement.content"></div>
            </div>
          </div>
          <div class="card-body" v-if="announcement.method == 'sms'">
            <div class="form-group">
              <label>Recipients</label>
              <input type="text" disabled v-model="announcement.type" class="form-control">
            </div>
            <div class="form-group">
              <label>Content</label>
              <div class="border p-3" v-html="announcement.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Recipients ({{ announcement.recipients.length }})</h6>
            <ol style="height: 300px; overflow-y: auto">
              <li v-for="(recipient, i) in announcement.recipients" :key="`recipient-${i}`">{{ recipient }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      announcement: { recipients: [] },
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/announcements/${this.$route.params.id}`).then(response => {
        this.announcement = response.data.announcement
        this.loading = false
      })
    },

    destroy() {
      this.loading = true

      this.$axios.delete(`/api/v1/admin/announcements/${this.$route.params.id}`).then(() => {
        this.loading = false
        this.$router.push({ name: 'admin.announcements.index' })
      })
    }
  }
}
</script>